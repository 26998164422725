import { Box, Button, Flex } from '@chakra-ui/core'
import React from 'react'


import {
  getIndexLookupTableByClones,
  getLookupTableForNextSlides,
  getSlidesToSlide,
  notEnoughChildren,
} from './functions'
import { SliderInternalState, SliderProps } from './types'

type DotsTypes = {
  props: SliderProps
  state: SliderInternalState
  goToSlide: (index: number) => void
  dotsBgColor?: string
}

const SliderDots: React.FC<DotsTypes> = ({
  props,
  state,
  dotsBgColor = 'white',
  goToSlide,
}) => {
  const { infinite, children } = props
  if (notEnoughChildren(state)) {
    return null
  }

  const { currentSlide, slidesToShow } = state
  const slidesToSlide = getSlidesToSlide(state, props)
  const childrenArr = React.Children.toArray(children)
  let numberOfDotsToShow: number
  if (!infinite) {
    numberOfDotsToShow =
      Math.ceil((childrenArr.length - slidesToShow) / slidesToSlide!) + 1
  } else {
    numberOfDotsToShow = Math.ceil(childrenArr.length / slidesToSlide!)
  }
  const nextSlidesTable = getLookupTableForNextSlides(
    numberOfDotsToShow,
    state,
    props,
    childrenArr
  )
  const lookupTable = getIndexLookupTableByClones(slidesToShow, childrenArr)
  const currentSlides = lookupTable[currentSlide]
  return (
    <Flex
      direction="row"
      zIndex={1}
      position="absolute"
      justify="center"
      align="center"
      bottom={0}
      left={0}
      right={0}
    >
      {Array(numberOfDotsToShow)
        .fill(0)
        .map((_, index: number) => {
          let isActive: boolean
          let nextSlide: number

          if (!infinite) {
            const maximumNextSlide = childrenArr.length - slidesToShow
            const possibileNextSlides = index * slidesToSlide!
            const isAboutToOverSlide = possibileNextSlides > maximumNextSlide
            nextSlide = isAboutToOverSlide
              ? maximumNextSlide
              : possibileNextSlides
            isActive =
              nextSlide === currentSlide ||
              (currentSlide > nextSlide &&
                currentSlide < nextSlide + slidesToSlide! &&
                currentSlide < childrenArr.length - slidesToShow)
          } else {
            nextSlide = nextSlidesTable[index]
            const cloneIndex = lookupTable[nextSlide]
            isActive =
              currentSlides === cloneIndex ||
              (currentSlides >= cloneIndex &&
                currentSlides < cloneIndex + slidesToSlide!)
          }
          return (
            <Box
              as="span"
              key={`slider-dot-${index}`}
              cursor="pointer"
              borderRadius="round"
              bg="white"
              boxSizing="border-box"
              w="12px"
              h="12px"
              ml="5px"
              opacity={isActive ? 1 : 0.4}
              onClick={() => goToSlide(nextSlide)}
            />
          )
        })}
    </Flex>
  )
}

export default SliderDots
