import Head from 'next/head'
import React from 'react'
import { useTranslation } from 'react-i18next'

type PageHeaderProps = {
  title: string
  description?: string
  image_url?: string
  ogType?: 'website' | 'product'
  ogDescription?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  title,
  description,
  image_url = 'https://cdn.shopify.com/s/files/1/0044/1237/5107/files/CompleteOpen_copy_1200x1200.jpg?v=1567810457',
  ogType = 'website',
  ogDescription,
}) => {
  const { t } = useTranslation()

  const desc =
    description ||
    t(
      'meta:description',
      "It's time for men to take control of their faces. Lumin simplifies the daily routines for guys to combat dark eye circles, acne scarring, dry skin, lines and wrinkles, clogged pores caused by oily skin, and more. Invest in your face the way you do in other aspects of life, and EARN your best look ever."
    )

  const getTranslationKey = (translationTitle: String) => {
    const sanitizedTitle = translationTitle.replace(/[^a-zA-Z ]/g, '')
    return `title:${sanitizedTitle.replace(/\s/g, '-')}`
  }

  const translatedTitle = t(getTranslationKey(title), title)

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.webp" />
        <link rel="canonical" href="https://www.luminskin.com/" />
        <title data-english-title={title}>
          {translatedTitle} | Lumin Skincare
        </title>
        <meta name="description" content={desc} />
        <meta name="theme-color" content="#4b5548" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={ogDescription || desc} />
        <meta property="og:image" content={image_url} />
        <meta property="og:site_name" content="Lumin" />
        <meta property="og:type" content={ogType} />
        <meta property="og:image:alt" content={title} />
      </Head>
      {children}
    </>
  )
}
export default PageHeader
