import React from 'react'

import {
  ResponsiveType,
  SliderProps,
  SliderInternalState,
  Table,
  NextSlidesTable,
} from './types'

export function getWidthFromDeviceType(
  deviceType: string,
  responsive: ResponsiveType
): number | string | undefined {
  let itemWidth: number | string | undefined

  if (responsive[deviceType]) {
    const { items } = responsive[deviceType]
    itemWidth = (100 / items).toFixed(1)
  }
  return itemWidth
}

export function getPartialVisibilityGutter(
  responsive: ResponsiveType,
  partialVisible?: boolean,
  serverSideDeviceType?: string | undefined,
  clientSideDeviceType?: string | undefined
): number | undefined {
  let gutter: number | undefined = 0
  const deviceType = clientSideDeviceType || serverSideDeviceType

  if (partialVisible && deviceType) {
    gutter = responsive[deviceType].partialVisibilityGutter
  }

  return gutter
}

export function getIfSlideIsVisbile(
  index: number,
  state: SliderInternalState
): boolean {
  const { currentSlide, slidesToShow } = state
  return index >= currentSlide && index < currentSlide + slidesToShow
}

export function getInitialState(
  state: SliderInternalState,
  props: SliderProps,
): {
  shouldRenderOnSSR: boolean
  flexBasis: number | string | undefined
  domFullyLoaded: boolean
  partialVisibilityGutter: number | undefined
  shouldRenderAtAll: boolean
} {
  const { slidesToShow, containerWidth, itemWidth, domLoaded } = state
  const { deviceType, responsive, ssr, partialVisible } = props
  let flexBasis: number | string | undefined
  const domFullyLoaded = Boolean(
    domLoaded && slidesToShow && containerWidth && itemWidth
  )
  if (ssr && deviceType && !domFullyLoaded) {
    flexBasis = getWidthFromDeviceType(deviceType, responsive)
  }
  const shouldRenderOnSSR = Boolean(
    ssr && deviceType && !domFullyLoaded && flexBasis
  )
  const partialVisibilityGutter = getPartialVisibilityGutter(
    responsive,
    partialVisible,
    deviceType,
    state.deviceType
  )
  const shouldRenderAtAll = shouldRenderOnSSR || domFullyLoaded
  return {
    shouldRenderOnSSR,
    flexBasis,
    domFullyLoaded,
    partialVisibilityGutter,
    shouldRenderAtAll,
  }
}

export function getClones(slidesToShow: number, childrenArr: any[]) {
  if (childrenArr.length < slidesToShow) {
    return childrenArr
  }
  if (childrenArr.length > slidesToShow * 2) {
    return [
      ...childrenArr.slice(
        childrenArr.length - slidesToShow * 2,
        childrenArr.length
      ),
      ...childrenArr,
      ...childrenArr.slice(0, slidesToShow * 2),
    ]
  }
  return [...childrenArr, ...childrenArr, ...childrenArr]
}

export function getItemClientSideWidth(
  slidesToShow: number,
  containerWidth: number
): number {
  return Math.round(containerWidth / slidesToShow)
}

export function getSlidesToSlide(
  state: SliderInternalState,
  props: SliderProps,
): number {
  const { slidesToShow, containerWidth, itemWidth, domLoaded } = state
  const { deviceType, responsive } = props
  let slidesToScroll = props.slidesToSlide || 1
  const domFullyLoaded = Boolean(
    domLoaded && slidesToShow && containerWidth && itemWidth
  )
  const ssr = props.ssr && props.deviceType && !domFullyLoaded
  if (ssr) {
    Object.keys(responsive).forEach((device) => {
      const { slidesToSlide } = responsive[device]
      if (deviceType === device && slidesToSlide) {
        slidesToScroll = slidesToSlide
      }
    })
  }
  if (domFullyLoaded) {
    Object.keys(responsive).forEach((item) => {
      const { breakpoint, slidesToSlide } = responsive[item]
      const { max, min } = breakpoint
      if (
        slidesToSlide &&
        window.innerWidth >= min &&
        window.innerWidth <= max
      ) {
        slidesToScroll = slidesToSlide
      }
    })
  }
  return slidesToScroll
}

export function getTransformForPartialVsibile(
  state: SliderInternalState,
  props: SliderProps,
  partialVisibilityGutter = 0,
  transformPlaceHolder?: number
) {
  const { currentSlide, slidesToShow } = state
  const isRightEndReach = isInRightEnd(state)
  const shouldRemoveRightGutter = !props.infinite && isRightEndReach
  const baseTransform = transformPlaceHolder || state.transform
  if (notEnoughChildren(state)) {
    return baseTransform
  }
  const transform = baseTransform + currentSlide * partialVisibilityGutter
  if (shouldRemoveRightGutter) {
    const remainingWidth =
      state.containerWidth -
      (state.itemWidth - partialVisibilityGutter) * slidesToShow
    return transform + remainingWidth
  }
  return transform
}

export function getTransformForCenterMode(
  state: SliderInternalState,
  props: SliderProps,
  transformPlaceHolder?: number
) {
  const transform = transformPlaceHolder || state.transform
  if (
    (!props.infinite && state.currentSlide === 0) ||
    notEnoughChildren(state)
  ) {
    return transform
  } else {
    return transform + state.itemWidth / 2
  }
}

export function getTransform(
  state: SliderInternalState,
  props: SliderProps,
  transformPlaceHolder?: number
) {
  const { partialVisible, responsive, deviceType } = props
  const transform = transformPlaceHolder || state.transform
  const partialVisibilityGutter = getPartialVisibilityGutter(
    responsive,
    partialVisible,
    deviceType,
    state.deviceType
  )

  const currentTransform = partialVisible
    ? getTransformForPartialVsibile(
        state,
        props,
        partialVisibilityGutter,
        transformPlaceHolder
      )
    : state.centerMode
    ? getTransformForCenterMode(state, props)
    : transform
  return currentTransform
}

export function getIndexLookupTableByClones(
  slidesToShow: number,
  childrenArr: any[]
): Table {
  if (childrenArr.length > slidesToShow * 2) {
    const table: Table = {}
    const firstBeginningOfClones = childrenArr.length - slidesToShow * 2
    const firstEndOfClones = childrenArr.length - firstBeginningOfClones
    let firstCount = firstBeginningOfClones
    for (let i = 0; i < firstEndOfClones; i++) {
      table[i] = firstCount
      firstCount++
    }
    const secondBeginningOfClones = childrenArr.length + firstEndOfClones
    const secondEndOfClones =
      secondBeginningOfClones + childrenArr.slice(0, slidesToShow * 2).length
    let secondCount = 0
    for (let i = secondBeginningOfClones; i <= secondEndOfClones; i++) {
      table[i] = secondCount
      secondCount++
    }
    const originalStart = firstEndOfClones
    const originalEnd = secondBeginningOfClones
    let originalCounter = 0
    for (let i = originalStart; i < originalEnd; i++) {
      table[i] = originalCounter
      originalCounter++
    }
    return table
  } else {
    const table: Table = {}
    const totalSlides = childrenArr.length * 3
    let count = 0
    for (let i = 0; i < totalSlides; i++) {
      table[i] = count
      count++
      if (count === childrenArr.length) {
        count = 0
      }
    }
    return table
  }
}

export function getOriginalChildrenCount(
  index: number,
  {
    slidesToShow,
    currentSlide,
  }: { slidesToShow: number; currentSlide: number; totalItems: number },
  childrenArr: any[]
): number {
  if (childrenArr.length > slidesToShow * 2) {
    const originalCouterPart = index + slidesToShow * 2
    return originalCouterPart
  } else {
    if (currentSlide >= childrenArr.length) {
      return childrenArr.length + index
    } else {
      return index
    }
  }
}

export function getLookupTableForNextSlides(
  numberOfDotsToShow: number,
  state: SliderInternalState,
  props: SliderProps,
  childrenArr: any[],
): NextSlidesTable {
  const table: NextSlidesTable = {}
  const slidesToSlide = getSlidesToSlide(state, props)

  Array(numberOfDotsToShow)
    .fill(0)
    .forEach((_, i) => {
      const nextSlide = getOriginalChildrenCount(i, state, childrenArr)

      if (i === 0) {
        table[0] = nextSlide
      } else {
        const prevIndex = i - 1
        const now = table[prevIndex] + slidesToSlide!
        table[i] = now
      }
    })
  return table
}

export function checkClonesPosition(
  { currentSlide, slidesToShow, itemWidth, totalItems }: SliderInternalState,
  childrenArr: any[],
  props: SliderProps
): {
  isReachingTheEnd: boolean
  isReachingTheStart: boolean
  nextSlide: number
  nextPosition: number
} {
  let nextSlide = 0
  let nextPosition = 0
  let isReachingTheStart = currentSlide === 0
  const originalFirstSlide =
    childrenArr.length - (childrenArr.length - slidesToShow * 2)
  let isReachingTheEnd: boolean

  if (childrenArr.length < slidesToShow) {
    nextSlide = 0
    nextPosition = 0
    isReachingTheEnd = false
    isReachingTheStart = false
  } else if (childrenArr.length > slidesToShow * 2) {
    isReachingTheEnd = currentSlide >= originalFirstSlide + childrenArr.length
    if (isReachingTheEnd) {
      nextSlide = currentSlide - childrenArr.length
      nextPosition = -(itemWidth * nextSlide)
    }
    if (isReachingTheStart) {
      nextSlide = originalFirstSlide + (childrenArr.length - slidesToShow * 2)
      nextPosition = -(itemWidth * nextSlide)
    }
  } else {
    isReachingTheEnd = currentSlide >= childrenArr.length * 2
    if (isReachingTheEnd) {
      nextSlide = currentSlide - childrenArr.length
      nextPosition = -(itemWidth * nextSlide)
    }
    if (isReachingTheStart) {
      if (props.showDots) {
        nextSlide = childrenArr.length
        nextPosition = -(itemWidth * nextSlide)
      } else {
        nextSlide = totalItems - slidesToShow * 2
        nextPosition = -(itemWidth * nextSlide)
      }
    }
  }
  return {
    isReachingTheEnd,
    isReachingTheStart,
    nextSlide,
    nextPosition,
  }
}

export function notEnoughChildren(state: SliderInternalState): boolean {
  const { slidesToShow, totalItems } = state
  return totalItems < slidesToShow
}

export function populateNextSlides(
  state: SliderInternalState,
  props: SliderProps,
  slidesHavePassed = 0,
): {
  nextSlides: number | undefined
  nextPosition: number | undefined
} {
  const { slidesToShow, currentSlide, itemWidth, totalItems } = state
  const slidesToSlide = getSlidesToSlide(state, props)
  let nextSlides: number | undefined
  let nextPosition: number | undefined

  const nextMaximumSlides =
    currentSlide +
    1 +
    slidesHavePassed +
    slidesToShow +
    (slidesHavePassed > 0 ? 0 : slidesToSlide!)
  if (nextMaximumSlides <= totalItems) {
    // It means if we have next slides go back to on the right-hand side.
    nextSlides =
      currentSlide +
      slidesHavePassed +
      (slidesHavePassed > 0 ? 0 : slidesToSlide!)
    nextPosition = -(itemWidth * nextSlides)
  } else if (
    nextMaximumSlides > totalItems &&
    currentSlide !== totalItems - slidesToShow
  ) {
    nextSlides = totalItems - slidesToShow
    nextPosition = -(itemWidth * nextSlides)
  } else {
    nextSlides = undefined
    nextPosition = undefined
  }
  return {
    nextSlides,
    nextPosition,
  }
}

export function isInRightEnd({
  currentSlide,
  totalItems,
  slidesToShow,
}: SliderInternalState): boolean {
  return !(currentSlide + slidesToShow < totalItems)
}

export function isInLeftEnd({ currentSlide }: SliderInternalState): boolean {
  return !(currentSlide > 0)
}

export function populatePreviousSlides(
  state: SliderInternalState,
  props: SliderProps,
  slidesHavePassed = 0,
): {
  nextSlides: number | undefined
  nextPosition: number | undefined
} {
  const { currentSlide, itemWidth, slidesToShow } = state
  const { children, showDots, infinite } = props
  const slidesToSlide = getSlidesToSlide(state, props)
  let nextSlides
  let nextPosition
  const nextMaximumSlides =
    currentSlide -
    slidesHavePassed -
    (slidesHavePassed > 0 ? 0 : slidesToSlide!)
  const childrenArr = React.Children.toArray(children)
  const additionalSlides = (childrenArr.length - slidesToShow) % slidesToSlide!
  if (nextMaximumSlides >= 0) {
    // It means if we have next slides go back to on the left-hand side.
    nextSlides = nextMaximumSlides
    if (showDots && !infinite && additionalSlides > 0 && isInRightEnd(state)) {
      nextSlides = currentSlide - additionalSlides
    }
    nextPosition = -(itemWidth * nextSlides)
  } else if (nextMaximumSlides < 0 && currentSlide !== 0) {
    // prevent oversliding.
    // it means the user has almost scrolling over to what we have.
    // if true, then we go back to the first slide.
    // this is not for infinite mode as infinite mode always has items to go back to.
    nextSlides = 0
    nextPosition = 0
  } else {
    nextSlides = undefined
    nextPosition = undefined
  }
  return {
    nextSlides,
    nextPosition,
  }
}

export function getInitialSlideInInfiniteMode(
  slidesToShow: number,
  childrenArr: any[]
) {
  if (childrenArr.length > slidesToShow * 2) {
    return slidesToShow * 2
  } else {
    return childrenArr.length
  }
}
