import { Box, Flex } from '@chakra-ui/core'
import React, { createRef, Component, RefObject, Children } from 'react'
import Swipe from 'react-easy-swipe'

import NextButton from '../NextButton'
import PrevButton from '../PrevButton'
import {
  getClones,
  checkClonesPosition,
  getInitialState,
  getItemClientSideWidth,
  getTransform,
  populateNextSlides,
  populatePreviousSlides,
  isInLeftEnd,
  isInRightEnd,
  getInitialSlideInInfiniteMode,
  notEnoughChildren,
} from './functions'
import SliderDots from './SliderDots'
import SliderItems from './SliderItems'
import SlideTopDots from './SlideTopDots'
import { SliderProps, SliderInternalState } from './types'

const defaultTransitionDuration = 400

// This component was created and custmized from https://github.com/YIZHUANG/react-multi-carousel
class Slider extends Component<SliderProps, SliderInternalState> {
  static defaultProps = {
    slidesToSlide: 1,
    infinite: false,
    arrows: true,
    keyBoardControl: true,
    showDots: false,
    renderDotsOutside: false,
    renderButtonGroupOutside: false,
    additionalTransfrom: 0,
    swipeable: true,
    fullWidth: true,
    showTopDots: false,
    renderArrowOutside: false,
  }

  containerRef: RefObject<HTMLDivElement>
  listRef: RefObject<HTMLUListElement>

  isAnimationAllowed: boolean
  transformPlaceHolder: number
  itemsToShowTimeout: NodeJS.Timeout

  constructor(props: SliderProps) {
    super(props)
    this.containerRef = createRef()
    this.listRef = createRef()

    this.state = {
      itemWidth: 0,
      slidesToShow: 0,
      currentSlide: 0,
      totalItems: React.Children.count(props.children),
      transform: 0,
      containerWidth: 0,
      centerMode: false,
      showArrows: false,
      showDots: false,
      domLoaded: false,
    }

    this.isAnimationAllowed = false
    this.transformPlaceHolder = 0
  }

  setTransformDirectly = (position: number, withAnimation?: boolean) => {
    const { additionalTransfrom } = this.props
    const currentTransform = getTransform(this.state, this.props, position)
    this.transformPlaceHolder = position

    if (this.listRef && this.listRef.current) {
      this.setAnimationDirectly(withAnimation)
      this.listRef.current.style.transform = `translate3d(${
        currentTransform + additionalTransfrom!
      }px,0,0)`
    }
  }

  setAnimationDirectly = (animationAllowed?: boolean) => {
    if (this.listRef && this.listRef.current) {
      if (animationAllowed) {
        this.listRef.current.style.transition = this.props.transition
      } else {
        this.listRef.current.style.transition = 'none'
      }
    }
  }

  componentDidMount() {
    this.setState({ domLoaded: true })
    this.setItemsToShow()

    window.addEventListener('resize', this.onResize as React.EventHandler<any>)
    this.onResize(true)
    if (this.props.keyBoardControl) {
      window.addEventListener('keyup', this.onKeyUp as React.EventHandler<any>)
    }
  }

  componentDidUpdate(
    { children }: SliderProps,
    { currentSlide, domLoaded }: SliderInternalState
  ) {
    if (Children.count(children) !== Children.count(this.props.children)) {
      setTimeout(() => {
        if (this.props.infinite) {
          this.setClones(
            this.state.slidesToShow,
            this.state.itemWidth,
            true,
            true
          )
        } else {
          this.resetTotalItems()
          this.setItemsToShow()
        }
      }, this.props.transitionDuration || defaultTransitionDuration)
    } else if (
      this.props.infinite &&
      this.state.currentSlide !== currentSlide
    ) {
      // this is to quickly cancel the animation and move the items position to create the infinite effects.
      this.correctClonesPosition({ domLoaded })
    } else if (this.props.children !== children && !this.props.infinite) {
      this.resetTotalItems()
    }

    if (this.transformPlaceHolder !== this.state.transform) {
      this.transformPlaceHolder = this.state.transform
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.onResize as React.EventHandler<any>
    )
    if (this.props.keyBoardControl) {
      window.removeEventListener(
        'keyup',
        this.onKeyUp as React.EventHandler<any>
      )
    }
    if (this.itemsToShowTimeout) {
      clearTimeout(this.itemsToShowTimeout)
    }
  }

  resetTotalItems = () => {
    const totalItems = React.Children.count(this.props.children)
    //always reset to the first slide if children count changes
    const currentSlide = 0
    this.setState(
      {
        totalItems,
        currentSlide,
      },
      () => {
        this.setContainerAndItemWidth(this.state.slidesToShow, true)
      }
    )
  }

  setClones = (
    slidesToShow: number,
    itemWidth?: number,
    forResizing?: boolean,
    resetCurrentSlide = false
  ) => {
    this.isAnimationAllowed = false
    const childrenArr = React.Children.toArray(this.props.children)
    const initialSlide = getInitialSlideInInfiniteMode(
      slidesToShow || this.state.slidesToShow,
      childrenArr
    )
    const clones = getClones(this.state.slidesToShow, childrenArr)
    const currentSlide =
      childrenArr.length < this.state.slidesToShow ? 0 : this.state.currentSlide
    this.setState(
      {
        totalItems: clones.length,
        currentSlide:
          forResizing && !resetCurrentSlide ? currentSlide : initialSlide,
      },
      () => {
        this.correctItemsPosition(itemWidth || this.state.itemWidth)
      }
    )
  }

  setItemsToShow = (
    shouldCorrectItemPosition?: boolean,
    resetCurrentSlide?: boolean
  ) => {
    const { responsive } = this.props
    Object.keys(responsive).forEach((item) => {
      const {
        breakpoint,
        items,
        centerMode,
        showArrows,
        showDots,
        reponsiveClassName,
      } = responsive[item]
      const { max, min } = breakpoint
      if (window.innerWidth >= min && window.innerWidth <= max) {
        this.setState({
          slidesToShow: items,
          deviceType: item,
          centerMode,
          showArrows,
          showDots,
          reponsiveClassName,
        })
        this.setContainerAndItemWidth(
          items,
          shouldCorrectItemPosition,
          resetCurrentSlide
        )
      }
    })
  }

  setContainerAndItemWidth = (
    slidesToShow: number,
    shouldCorrectItemPosition?: boolean,
    resetCurrentSlide?: boolean
  ) => {
    if (this.containerRef && this.containerRef.current) {
      const containerWidth = this.containerRef.current.offsetWidth

      const itemWidth: number = getItemClientSideWidth(
        slidesToShow,
        containerWidth
      )

      this.setState(
        {
          containerWidth,
          itemWidth,
        },
        () => {
          if (this.props.infinite) {
            this.setClones(
              slidesToShow,
              itemWidth,
              shouldCorrectItemPosition,
              resetCurrentSlide
            )
          }
        }
      )
      if (shouldCorrectItemPosition) {
        this.correctItemsPosition(itemWidth)
      }
    }
  }

  correctItemsPosition = (
    itemWidth: number,
    isAnimationAllowed?: boolean,
    setToDomDirectly?: boolean
  ) => {
    if (isAnimationAllowed) {
      this.isAnimationAllowed = true
    }
    if (!isAnimationAllowed && this.isAnimationAllowed) {
      this.isAnimationAllowed = false
    }
    const nextTransform =
      this.state.totalItems < this.state.slidesToShow
        ? 0
        : -(itemWidth * this.state.currentSlide)
    if (setToDomDirectly) {
      this.setTransformDirectly(nextTransform, true)
    }
    this.setState({
      transform: nextTransform,
    })
  }

  onResize = (value?: React.KeyboardEvent | boolean) => {
    const { infinite } = this.props
    let shouldCorrectItemPosition: boolean
    if (!infinite) {
      shouldCorrectItemPosition = false
    } else {
      if (value) {
        shouldCorrectItemPosition = false
      } else {
        shouldCorrectItemPosition = true
      }
    }
    this.setItemsToShow(shouldCorrectItemPosition)
  }

  correctClonesPosition = ({ domLoaded }: { domLoaded?: boolean }) => {
    const childrenArr = React.Children.toArray(this.props.children)
    const { isReachingTheEnd, isReachingTheStart, nextSlide, nextPosition } =
      checkClonesPosition(this.state, childrenArr, this.props)
    if (
      // this is to prevent this gets called on the server-side.
      this.state.domLoaded &&
      domLoaded
    ) {
      if (isReachingTheEnd || isReachingTheStart) {
        this.isAnimationAllowed = false
        setTimeout(() => {
          this.setState({
            transform: nextPosition,
            currentSlide: nextSlide,
          })
        }, this.props.transitionDuration || defaultTransitionDuration)
      }
    }
  }

  goToSlide = (slide: number) => {
    const { itemWidth } = this.state

    this.isAnimationAllowed = true
    this.setState(
      {
        currentSlide: slide,
        transform: -(itemWidth * slide),
      },
      () => {
        if (this.props.infinite) {
          this.correctClonesPosition({ domLoaded: true })
        }
      }
    )
  }

  next = (slidesHavePassed = 0) => {
    if (notEnoughChildren(this.state)) {
      return
    }
    const { nextSlides, nextPosition } = populateNextSlides(
      this.state,
      this.props,
      slidesHavePassed
    )

    if (nextSlides === undefined || nextPosition === undefined) {
      return
    }
    this.isAnimationAllowed = true
    this.setState({
      transform: nextPosition,
      currentSlide: nextSlides,
    })
  }

  previous = (slidesHavePassed = 0) => {
    if (notEnoughChildren(this.state)) {
      return
    }
    const { nextSlides, nextPosition } = populatePreviousSlides(
      this.state,
      this.props,
      slidesHavePassed
    )
    if (nextSlides === undefined || nextPosition === undefined) {
      return
    }

    this.isAnimationAllowed = true
    this.setState({
      transform: nextPosition,
      currentSlide: nextSlides,
    })
  }

  onKeyUp = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowLeft':
        return this.previous()
      case 'ArrowRight':
        return this.next()
    }
  }

  renderLeftArrow = (): React.ReactNode => {
    const { roundedArrows, arrowPosition } = this.props

    return (
      <PrevButton
        onClick={() => {
          this.previous()
        }}
        rounded={roundedArrows}
        left={arrowPosition}
        arrowColor={this.props.arrowButtonColor}
        bgColor={this.props.arrowButtonBg}
        size={this.props.arrowSize}
      />
    )
  }

  renderRightArrow = (): React.ReactNode => {
    const { roundedArrows, arrowPosition } = this.props

    return (
      <NextButton
        onClick={() => {
          this.next()
        }}
        rounded={roundedArrows}
        right={arrowPosition}
        arrowColor={this.props.arrowButtonColor}
        bgColor={this.props.arrowButtonBg}
        size={this.props.arrowSize}
      />
    )
  }

  renderArrows = ({
    shouldShowArrows,
    disableLeftArrow,
    disableRightArrow,
  }: {
    shouldShowArrows: boolean | undefined
    disableLeftArrow: boolean
    disableRightArrow: boolean
  }): React.ReactNode => {
    return (
      <>
        {shouldShowArrows && !disableLeftArrow && this.renderLeftArrow()}
        {shouldShowArrows && !disableRightArrow && this.renderRightArrow()}
      </>
    )
  }

  renderSliderDots = () => {
    return (
      <SliderDots
        state={this.state}
        props={this.props}
        goToSlide={this.goToSlide}
      />
    )
  }

  renderSliderlItems = () => {
    const { infinite, children } = this.props
    let clones: any[] = []

    if (infinite) {
      const childrenArr = React.Children.toArray(children)
      clones = getClones(this.state.slidesToShow, childrenArr)
    }

    return (
      <SliderItems
        clones={clones}
        state={this.state}
        notEnoughChildren={notEnoughChildren(this.state)}
        props={this.props}
      />
    )
  }

  render() {
    const {
      infinite,
      additionalTransfrom,
      showDots,
      swipeable,
      fullWidth,
      transition,
      showTopDots,
      renderArrowOutside,
    } = this.props

    const { showArrows } = this.state

    const { shouldRenderOnSSR, shouldRenderAtAll } = getInitialState(
      this.state,
      this.props
    )

    const isLeftEndReach = isInLeftEnd(this.state)
    const isRightEndReach = isInRightEnd(this.state)
    const shouldShowArrows =
      showArrows && !notEnoughChildren(this.state) && shouldRenderAtAll
    const disableLeftArrow = !infinite && isLeftEndReach
    const disableRightArrow = !infinite && isRightEndReach

    // supports showing next set of items partially as well as center mode which shows both.
    const currentTransform = getTransform(this.state, this.props)

    const swipeProps = {
      onSwipeLeft: this.next,
      onSwipeRight: this.previous,
      style: { height: '100%' },
      tolerance: 100,
    }

    return (
      <>
        {showTopDots && (
          <SlideTopDots
            state={this.state}
            props={this.props}
            goToSlide={this.goToSlide}
          />
        )}
        <Swipe
          {...(swipeable && swipeProps)}
          style={{
            height: '100%',
            width: fullWidth ? '100%' : 'auto',
            position: 'relative',
          }}
        >
          <Flex
            w="100%"
            h="100%"
            align="center"
            overflow="hidden"
            position="relative"
            ref={this.containerRef}
            pb={8}
          >
            <Box
              as="ul"
              ref={this.listRef}
              display="flex"
              listStyleType="none"
              p={0}
              m={0}
              position="relative"
              willChange="transform, transition"
              transition={this.isAnimationAllowed ? transition : 'none'}
              overflow={shouldRenderOnSSR ? 'hidden' : 'unset'}
              transform={`translate3d(${
                currentTransform + additionalTransfrom!
              }px,0,0)`}
              className={this.state.reponsiveClassName}
            >
              {this.renderSliderlItems()}
            </Box>
            {!renderArrowOutside &&
              this.renderArrows({
                shouldShowArrows,
                disableLeftArrow,
                disableRightArrow,
              })}
            {shouldRenderAtAll &&
              (showDots || this.state.showDots) &&
              this.renderSliderDots()}
          </Flex>
          {renderArrowOutside &&
            this.renderArrows({
              shouldShowArrows,
              disableLeftArrow,
              disableRightArrow,
            })}
        </Swipe>
      </>
    )
  }
}

export default Slider
