import { Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'

import { PreviousIcon } from './Icons/PreviousIcon'

type PrevButtonProps = {
  showControlsAbove?: boolean
  onClick?: () => void
  rounded?: boolean
  left?: FlexProps['left']
  size?: 'md' | 'sm'
  arrowColor?: FlexProps['color']
  bgColor?: FlexProps['bgColor']
}

const buttonSizeMap = {
  md: ['50px', null, '62px'],
  sm: ['35px', null, '40px'],
}

const iconSizeMap = {
  md: { w: '15px', h: '24px' },
  sm: { w: '10px', h: '24px' },
}

const PrevButton: React.FC<PrevButtonProps> = ({
  showControlsAbove,
  onClick,
  rounded,
  left = 0,
  size = 'md',
  arrowColor = 'black',
  bgColor = 'seaMist',
}) => {
  
  const buttonSize = buttonSizeMap[size]
  const iconSize = iconSizeMap[size]

  return (
    <Flex
      zIndex="header"
      justify="center"
      align="center"
      display="inline-flex"
      top={showControlsAbove ? 4 : '50%'}
      transform="translate(0, -50%)"
      left={left}
      position="absolute"
      bgColor={rounded ? bgColor : 'initial'}
      borderRadius="round"
      w={buttonSize}
      h={buttonSize}
      cursor="pointer"
      onClick={onClick}
    >
      <PreviousIcon
        color={arrowColor}
        w={iconSize.w}
        h={iconSize.h}
        transform="rotate(180deg)"
      />
    </Flex>
  )
}

export default PrevButton
