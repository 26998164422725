import { Flex, FlexProps } from '@chakra-ui/core'
import React from 'react'

import { NextIcon } from './Icons/NextIcon'

type NextButtonProps = {
  showControlsAbove?: boolean
  onClick?: () => void
  rounded?: boolean
  right?: FlexProps['left']
  size?: 'md' | 'sm'
  arrowColor?: FlexProps['color']
  bgColor?: FlexProps['bgColor']
}

const buttonSizeMap = {
  md: ['50px', null, '62px'],
  sm: ['35px', null, '40px'],
}

const iconSizeMap = {
  md: { w: '15px', h: '24px' },
  sm: { w: '10px', h: '24px' },
}

const NextButton: React.FC<NextButtonProps> = ({
  showControlsAbove,
  onClick,
  rounded,
  right = 0,
  size = 'md',
  arrowColor = 'black',
  bgColor = 'seaMist',
}) => {
  
  const buttonSize = buttonSizeMap[size]
  const iconSize = iconSizeMap[size]

  return (
    <Flex
      zIndex={1}
      justify="center"
      align="center"
      display="inline-flex"
      top={showControlsAbove ? 4 : '50%'}
      transform="translate(-0, -50%)"
      right={right}
      position="absolute"
      bgColor={rounded ? bgColor : 'initial'}
      borderRadius="round"
      w={buttonSize}
      h={buttonSize}
      cursor="pointer"
      onClick={onClick}
    >
      <NextIcon color={arrowColor} w={iconSize.w} h={iconSize.h} />
    </Flex>
  )
}

export default NextButton
