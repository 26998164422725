import { Box, Flex } from '@chakra-ui/core'
import React from 'react'

import {
  getIndexLookupTableByClones,
  getLookupTableForNextSlides,
  getSlidesToSlide,
  notEnoughChildren,
} from './functions'
import { SliderProps, SliderInternalState } from './types'


type DotsTypes = {
  props: SliderProps
  state: SliderInternalState
  goToSlide: (index: number) => void
  dotsBgColor?: string
}

const SlideTopDots: React.FC<DotsTypes> = ({
  props,
  state,
  dotsBgColor = 'black',
  goToSlide,
}) => {
  const { infinite, children } = props
  if (notEnoughChildren(state)) {
    return null
  }

  const { currentSlide, slidesToShow } = state
  const slidesToSlide = getSlidesToSlide(state, props)
  const childrenArr = React.Children.toArray(children)
  let numberOfDotsToShow: number
  if (!infinite) {
    numberOfDotsToShow =
      Math.ceil((childrenArr.length - slidesToShow) / slidesToSlide!) + 1
  } else {
    numberOfDotsToShow = Math.ceil(childrenArr.length / slidesToSlide!)
  }
  const nextSlidesTable = getLookupTableForNextSlides(
    numberOfDotsToShow,
    state,
    props,
    childrenArr
  )
  const lookupTable = getIndexLookupTableByClones(slidesToShow, childrenArr)
  const currentSlides = lookupTable[currentSlide]
  return (
    <Flex
      maxW="670px"
      position="relative"
      direction="column"
      align="center"
      width="100%"
    >
      <Box borderRadius="50%" bg="timelineRadialBg" height="2px" width="100%" />
      <Flex
        px={3}
        width="100%"
        top="-0.6rem"
        justify="space-between"
        position="absolute"
      >
        {Array(numberOfDotsToShow)
          .fill(0)
          .map((_, index: number) => {
            let isActive: boolean
            let nextSlide: number

            if (!infinite) {
              const maximumNextSlide = childrenArr.length - slidesToShow
              const possibileNextSlides = index * slidesToSlide!
              const isAboutToOverSlide = possibileNextSlides > maximumNextSlide
              nextSlide = isAboutToOverSlide
                ? maximumNextSlide
                : possibileNextSlides
              isActive =
                nextSlide === currentSlide ||
                (currentSlide > nextSlide &&
                  currentSlide < nextSlide + slidesToSlide! &&
                  currentSlide < childrenArr.length - slidesToShow)
            } else {
              nextSlide = nextSlidesTable[index]
              const cloneIndex = lookupTable[nextSlide]
              isActive =
                currentSlides === cloneIndex ||
                (currentSlides >= cloneIndex &&
                  currentSlides < cloneIndex + slidesToSlide!)
            }
            return (
              <Box
                w="1.3rem"
                h="1.3rem"
                cursor="pointer"
                borderRadius="50%"
                zIndex="5"
                bg={isActive ? 'black' : 'seastoneAplha80'}
                onClick={() => goToSlide(nextSlide)}
                key={`slider-top-dots-${index}`}
              />
            )
          })}
      </Flex>
    </Flex>
  )
}

export default SlideTopDots
